<mat-card appearance="outlined">
  <mat-card-content>

    <h1 mat-dialog-title>Create Collection on {{ registry }}</h1>
    <div class="mat-form-field-container">
      
      <mat-form-field>
        <input matInput id="id" placeholder="Collection ID" value="" (change)="inputChange($event)" required>
      </mat-form-field>
  
      <mat-form-field>
        <input matInput id="version" placeholder="Collection Version" value="" (change)="inputChange($event)" required>
      </mat-form-field>
  
      <mat-form-field>
        <input matInput id="name" placeholder="Collection Name" value="" (change)="inputChange($event)" required>
      </mat-form-field>
  
      <mat-form-field>
        <textarea matInput id="description" placeholder="Collection Description" value="" (change)="inputChange($event)" required></textarea>
      </mat-form-field>
  
      <mat-form-field>
        <textarea matInput id="citations" placeholder="Citations" value="" (change)="inputChange($event)"></textarea>
      </mat-form-field>
  
      <section>
        <mat-checkbox [(ngModel)]="overwrite" matTooltipShowDelay="1000" matTooltip="Overwrites a previous collection with what you're creating now.">Overwrite</mat-checkbox>
      </section>
  
    </div>
  
    <div *ngIf="working">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  
    <div mat-dialog-actions>
      <button mat-button (click)="onCancelClick()">Cancel</button>
      <button mat-button [disabled]="!finishCheck()" (click)="onCreateClick()">Create</button>
    </div>
  </mat-card-content>
</mat-card>