<mat-card>
  <mat-card-content>
    <h1 mat-dialog-title>Choose Color</h1>
    <mat-dialog-content>
      <span 
        [cpToggle]="true" 
        [cpDialogDisplay]="'inline'"
        (colorPickerChange)="this.selectedColor = $event" 
        [(colorPicker)]="this.data.initialColor"
        [cpOutputFormat] = "'hex'"
        [cpPresetColors] = "defaultColors"  
        [cpWidth] = "'350px'"
        >
      </span>
      <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="this.data.initialColor" (click)="onCancelClick()">Cancel</button>
        <button mat-button [mat-dialog-close]="this.selectedColor">Ok</button>
      </div>
    </mat-dialog-content>
  </mat-card-content>