<mat-toolbar color="primary">
  <mat-toolbar-row class="toolbar-row">

    <div style="width: 230.77px"><button mat-stroked-button class="toolbar-row-button"
            [matTooltip]="'Back to the Canvas'"
            [routerLink]="['/']"><mat-icon>exit_to_app</mat-icon></button>
    </div>

<!--    <div  style="text-align: center; width: 100%; position: absolute;">About</div>-->

    <span class="toolbar-spacer" style="font-weight: bold; font-size: 28pt">About</span>

    <img src="assets/banner.png"
         style="max-width:100%;max-height:100%" [routerLink]="['/']"/>
  </mat-toolbar-row>
</mat-toolbar>


<mat-card appearance="outlined" fxLayout="column" style="margin: 8px;" >
  <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-around center">
    <div style="max-width: 1200px;">
      <p class="about-text">
      Biologists often face the challenge of effectively communicating DNA sequences and their behaviors. An increasingly popular solution is SBOL, the Synthetic Biology Open Language. It is a diagram syntax that models genetic systems, allowing for both abstract visualizations and detailed data. Unfortunately, the complexity of creating SBOL documents is limiting this powerful language’s growth. SBOL, while characterized by graphic models, is lacking tools for graphical editing. Until now.
      </p>
      <p class="about-text">
      SBOL Canvas is an open source web-based graphical editor that opens synthetic biology design to anyone from students to advanced researchers. Users will enjoy a simple interface for creating diagrams that are both artistic and scientific. By interfacing with existing SBOL databases, SBOL Canvas lets users share their designs and easily reference the research of others. This editor significantly lowers SBOL’s barrier to entry, helping it carry synthetic biology to new heights.
      </p>
      <p class="about-text">
        SBOL Canvas is supported in part by the <a href="https://sbolstandard.org/sbol-industrial/" target="_blank">SBOL Industrial Consortium</a>. 
        It is currently being developed by those in the Genetic Logic Lab led by Dr. Chris Myers and is part of the Synthetic Biology Data Exchange Group. 
      </p>
    </div>
  </mat-card-content>
</mat-card>

<mat-grid-list cols="3"> 
  <mat-grid-tile>
    <figure>
      <a href="https://geneticlogiclab.org/" target="_blank">
        <img src="assets/about_pics/Genetic_Logic_Lab_Logo.png" alt="">
      </a>
      <figcaption class="about-text" style="text-align: center;">
        Genetic Logic Lab
      </figcaption>
    </figure>
  </mat-grid-tile> 

  <mat-grid-tile>
    <figure>
      <a href="https://github.com/SynBioDex/SBOLCanvas" target="_blank">
        <img src="assets/about_pics/SBOLCanvas_Logo.png" alt="">
      </a>
      <figcaption class="about-text" style="text-align: center;">SBOLCanvas</figcaption>
    </figure>
  </mat-grid-tile> 

  <mat-grid-tile>
    <figure>
      <a href="https://github.com/synbiodex" target="_blank">
        <img src="assets/about_pics/SynBioDex.png" alt="">
      </a>
      <figcaption class="about-text" style="text-align: center;">SynBioDex</figcaption>
    </figure>

  </mat-grid-tile> 
</mat-grid-list>


<div class="about-text" style="text-align: center; margin-top: 2rem;">
  <u>Contributors:</u>
</div>

<div class="about-text" style="text-align: center; margin-bottom: 5rem;">
  <ul style="display: inline-block; text-align: left;">
    @for (contributor of contributors; track $index) {
      <li>
        {{contributor}}
      </li>
    }
  </ul>
</div>
<p align="center">Version {{version}} ({{hash}})</p>
