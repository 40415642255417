<mat-card appearance="outlined">
  <mat-card-content>
    <h1 mat-dialog-title>Load</h1>
    <div mat-dialog-content>
        <button type="button" mat-stroked-button (click)="fileInput.click()">Choose File</button>
        <input hidden (change)="onFileSelected()" #fileInput type="file" id="file">
        <mat-label>{{filename}}</mat-label>
    </div>
  
    <div *ngIf="working">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  
    <div class="actions" mat-dialog-actions>
        <button mat-button (click)="onCancelClick()">Cancel</button>
        <button mat-button [disabled]="!finishCheck()" (click)="onOkClick()">Ok</button>
    </div>
  </mat-card-content>
</mat-card>