<mat-card appearance="outlined">
  <mat-card-content>

    <h1 mat-dialog-title *ngIf="!importMode">Upload</h1>
    <h1 mat-dialog-title *ngIf="importMode">Import</h1>
  
    <div mat-dialog-content *ngIf="importMode">
      <button mat-stroked-button (click)="fileInput.click()">{{filename}}</button>
      <input hidden (change)="onFileSelected()" #fileInput type="file" id="file">
    </div>
  
    <div class="mat-form-field-container">
  
      <mat-form-field>
        <mat-label>Registry</mat-label>
        <mat-select id="registry" [disabled]="registries == null || registries.length < 1"
          (selectionChange)="setRegistry($event.value)">
          <mat-option *ngFor="let registry of registries" [value]="registry" [matTooltip]="registry">
            {{registry}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter"/>
      </mat-form-field>
  
      <table mat-table [dataSource]="collections" matSort>
  
        <ng-container matColumnDef="displayId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Display Id</th>
          <td mat-cell *matCellDef="let element">{{element.displayId}}</td>
        </ng-container>
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
  
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
          <td mat-cell *matCellDef="let element">{{element.version}}</td>
        </ng-container>
  
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">{{element.description}}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="collection = row.uri"
          [style.background]="row.uri == collection ? 'lightblue' : ''"></tr>
      </table>
  
    </div>
  
    <div *ngIf="working">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  
    <div mat-dialog-actions>
      <button mat-button *ngIf="!loginDisabled()" [disabled]="!registry" (click)="onLoginClick()">Login</button>
      <button mat-button *ngIf="loginDisabled()" [disabled]="!registry" (click)="onLogoutClick()">Logout</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
      <button *ngIf="!importMode" mat-button [disabled]="!finishCheck()" (click)="onUploadClick()">Upload</button>
      <button *ngIf="importMode" mat-button [disabled]="!finishCheck()" (click)="onImportClick()">Import</button>
      <button mat-button [disabled]="!createCollectionCheck()" (click)="onCreateCollectionClick()">Create Collection</button>
      <button mat-button (click)="onAddRegistryClick()">Add Registry</button>
      @if (registries?.length > 1) {
        <button mat-button (click)="onDeleteRegistryClick()">Delete Registries</button>
      }
    </div>
  </mat-card-content>

</mat-card>
