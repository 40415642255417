<mat-card appearance="outlined">
    <mat-card-content>
  
      <h1 mat-dialog-title>Delete Registries</h1>
      <div class="mat-form-field-container">
        <mat-form-field>
            <mat-label>Registries</mat-label>
            <mat-select panelWidth="null" multiple [(value)]="deletedRegistries">
              @for (registry of registries; track $index) {
                <mat-option [value]="registry">{{registry}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
      </div>

      <div mat-dialog-actions>
        <button mat-button (click)="onCancelClick()">Cancel</button>
        <button mat-button (click)="onDeleteClick()">Delete</button>
      </div>
    </mat-card-content>
  </mat-card>