<mat-card appearance="outlined">
    <mat-card-content>
      <h1 mat-dialog-title>Add Registry</h1>
      <div class="mat-form-field-container">
        <mat-form-field>
          <input matInput 
          type="url"
          pattern="https?://.+"
          #registryURLInput="ngModel" 
          required 
          [(ngModel)] = "registryURL" 
          placeholder="Registry URL"
          />
        <mat-error>
          Must be URL
        </mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="onCancelClick()">Cancel</button>
        <button mat-button (click)="onCreateClick()" [disabled]="registryURLInput.invalid">Add</button>
      </div>
    </mat-card-content>
  </mat-card>