<mat-card appearance="outlined">
  <mat-card-content>
    <h1 mat-dialog-title>{{mode}} Design</h1>
    <div class="mat-form-field-container">
      
      <mat-form-field>
        <input matInput [(ngModel)]="filename" placeholder="Filename">
      </mat-form-field>
  
      <mat-form-field>
        <mat-label>Format</mat-label>
        <mat-select id="format"
          (selectionChange)="format = $event.value" value="{{format}}">
          <mat-option *ngFor="let format of formats" [value]="format">
            {{format}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
    </div>
  
    <div *ngIf="working">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  
    <div mat-dialog-actions>
      <button mat-button (click)="onCancelClick()">Cancel</button>
      <button mat-button (click)="onFinishClick()" [disabled]="!finishCheck()">{{mode}}</button>
    </div>
  </mat-card-content>

</mat-card>