<div class="zoom-controls">
    <button mat-icon-button [matTooltip]="'Zoom Out'" (click)="graphService.zoomOut(); displayTooltip()">
        <mat-icon>zoom_out</mat-icon>
    </button>
    <mat-slider aria-label="zoom" min="10" max="400" step="5" #ngSlider>
        <input matSliderThumb
        [value]="getZoomSliderValue()"
        (input)="zoomSliderChanged({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})"
        #ngSliderThumb="matSliderThumb"
        #tooltip = "matTooltip"
        [matTooltip]="getZoomDisplayValue()"
        (click) = "displayTooltip()"
        aria-label="Zoom Level"/>  
    </mat-slider>
    <button mat-icon-button [matTooltip]="'Zoom In'" (click)="graphService.zoomIn(); displayTooltip()">
        <mat-icon>zoom_in</mat-icon>
    </button>
</div>